import React from 'react';
import './App.css';
import Navbar from './components/Navbar';

const App = () => {
  return (
    <div className="font-[Poppins] h-screen">
      <Navbar />
      <div className="bg-gradient-to-t from-purple-700 to-blue-700 min-h-screen text-white flex flex-col items-center justify-center">
      <div className="max-w-4xl mx-auto bg-white text-black p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-4">Welcome to AcadInsight</h1>
        <p className="text-lg leading-relaxed">
          AcadInsight, derived from the words "acad" meaning academics and "insights" meaning insights, provides valuable insights about your academic performance. 
          Many students struggle at the end of their semester exams to improve their CGPA, often finding it challenging to find a proper tool or method to achieve their goals. 
          AcadInsight is designed to help you by offering precise guidance on what to study, where, when, and how to enhance your academic performance and succeed in your exams.
        </p>
      </div>
    </div>
    </div>
  );
};

export default App;
