// src/components/Developers.js
import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const Developers = () => {
  const [developers, setDevelopers] = useState([]);

  useEffect(() => {
    fetch('/developers.json')
      .then((response) => response.json())
      .then((data) => setDevelopers(data))
      .catch((error) => console.error('Error fetching developer data:', error));
  }, []);

  return (
    <>
      <Navbar />
      <div className="bg-gradient-to-t from-purple-700 to-blue-700 min-h-screen flex flex-col items-center p-8">
        <h1 className="text-4xl font-bold text-white mb-8">Meet Our Developers</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {developers.map((dev, index) => (
            <div
              key={index}
              className="relative bg-white p-6 rounded-lg shadow-lg backdrop-blur-md bg-opacity-90 transition-transform transform hover:scale-105 hover:shadow-xl"
            >
              <img
                src={dev.image}
                alt={dev.name}
                className="w-24 h-24 rounded-full mx-auto mb-4 border-2 border-gray-300"
              />
              <h2 className="text-2xl font-semibold mb-2">{dev.name}</h2>
              <h3 className="text-lg font-medium mb-4">{dev.role}</h3>
              <p className="mb-4">{dev.bio}</p>
              <div className="flex space-x-4">
                <a
                  href={dev.socialLinks.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline flex items-center space-x-2"
                >
                  <FaLinkedin className="text-xl" />
                  <span>LinkedIn</span>
                </a>
                <a
                  href={dev.socialLinks.github}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-800 hover:underline flex items-center space-x-2"
                >
                  <FaGithub className="text-xl" />
                  <span>GitHub</span>
                </a>
              </div>
              {/* Optional: Add a shiny effect overlay */}
              {/* <div className="absolute inset-0 rounded-lg bg-gradient-to-t from-white/20 to-transparent opacity-0 hover:opacity-100 transition-opacity"></div> */}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Developers;
