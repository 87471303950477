import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import DashboardPage from './pages/DashboardPage';
import Home from './Home';
import AboutUs from './pages/AboutUs';
import 'tailwindcss/tailwind.css';
import Developers from './pages/Developers';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/developers" element={<Developers />} />
        <Route path="/login" Component={LoginPage} />
        <Route path="/signup" Component={SignupPage} />
        <Route path="/dashboard" Component={DashboardPage} />
      </Routes>
    </Router>
  );
}

export default App;
