import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';

function DashboardPage() {
  const [standard, setStandard] = useState(localStorage.getItem('standard') || '');
  const [subjects, setSubjects] = useState(JSON.parse(localStorage.getItem('subjects')) || []);
  const [result, setResult] = useState('');
  const [error, setError] = useState('');

  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const res = await axios.get('https://ai-backend-amber.vercel.app/api/student/data', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.data.students) {
          setStandard(res.data.students.standard);
          setSubjects(res.data.students.subjects);
          localStorage.setItem('standard', res.data.students.standard);
          localStorage.setItem('subjects', JSON.stringify(res.data.students.subjects));
        }
      } catch (err) {
        console.error('Client side error:', err);
        setError('Error fetching student data. Please try again.');
      }
    };
    fetchStudentData();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        'https://ai-backend-amber.vercel.app/api/student/analyze',
        { standard, subjects },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      setResult(res.data.analysis);
    } catch (err) {
      console.error('Client side error:', err);
      setError('Error analyzing student performance. Please try again.');
    }
  };

  const handleAddSubject = () => {
    setSubjects([...subjects, { name: '', percentage: 0 }]);
  };

  const handleRemoveSubject = (index) => {
    const newSubjects = [...subjects];
    newSubjects.splice(index, 1);
    setSubjects(newSubjects);
  };

  const handleSubjectChange = (index, field, value) => {
    const newSubjects = [...subjects];
    newSubjects[index][field] = value;
    setSubjects(newSubjects);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-t from-[#882c86] to-[#2069e2] p-4">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">Dashboard</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            value={standard}
            onChange={(e) => setStandard(e.target.value)}
            placeholder="Standard"
            required
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          {subjects.map((subject, index) => (
            <div key={index} className="flex items-center space-x-4">
              <input
                type="text"
                value={subject.name}
                onChange={(e) => handleSubjectChange(index, 'name', e.target.value)}
                placeholder="Subject Name"
                required
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <input
                type="number"
                value={subject.percentage}
                onChange={(e) => handleSubjectChange(index, 'percentage', e.target.value)}
                placeholder="Percentage"
                required
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <button 
                type="button" 
                onClick={() => handleRemoveSubject(index)}
                className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600"
              >
                Remove
              </button>
            </div>
          ))}
          <button 
            type="button" 
            onClick={handleAddSubject}
            className="bg-blue-500 text-white px-4 py-2 mr-10 rounded-md hover:bg-blue-600"
          >
            Add Subject
          </button>
          <button 
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            Analyze
          </button>
        </form>
        {result && <div className="mt-4 p-4 bg-green-100 text-green-800 rounded-md">{result}</div>}
        {error && <div className="mt-4 p-4 bg-red-100 text-red-800 rounded-md">{error}</div>}
      </div>
    </div>
  );
}

export default DashboardPage;
