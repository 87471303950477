import React from 'react';
import Navbar from '../components/Navbar'

const AboutUs = () => {
  return (
    <>
    <Navbar/>
    <div className="bg-gradient-to-t from-purple-700 to-blue-700 min-h-screen text-white flex flex-col items-center justify-center">
      <div className="max-w-4xl mx-auto bg-white text-black p-8 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold mb-4">About Us</h1>
        <p className="text-lg leading-relaxed mb-4">
          AcadInsight is a cutting-edge platform designed to provide students with valuable insights into their academic performance. 
          Our goal is to assist students in improving their CGPA and excelling in their exams by offering tailored guidance and precise recommendations. 
          We understand the struggles students face at the end of their semester exams, and we strive to make their academic journey smoother and more successful.
        </p>
        <p className="text-lg leading-relaxed">
          Our platform combines innovative technology with expert advice to deliver actionable insights and personalized study plans. 
          With AcadInsight, you'll know exactly what to study, where to focus your efforts, and how to maximize your learning outcomes.
        </p>
      </div>
    </div>
    </>
  );
};

export default AboutUs;
